import "../../App.css";

import React, { useState, useCallback, useRef, useEffect } from "react";
import Confetti from "react-confetti";
import { Toaster, toast } from "react-hot-toast";
import RotatingTextBox from "../../components/RotatingTextBox";
import leapyearLogo from "../../images/leapyear.png";
import pioneerLogo from "../../images/pioneer.png";
import devIcon from "../../images/dev.png";
import locationIcon from "../../images/location.png";
import chartIcon from "../../images/chart.png";
import helpIcon from "../../images/help.png";
import fastIcon from "../../images/fast.png";
import gearIcon from "../../images/gear.png";
import bulbIcon from "../../images/bulb.png";
import mobileIcon from "../../images/mobile.png";
import saveIcon from "../../images/save.png";
import heartIcon from "../../images/heart.png";
import { FaHome, FaUser } from "react-icons/fa";
import Navbar from "../../components/Navbar";
import { Link, useNavigate } from "react-router-dom";
import packedCRM from "../../images/packedcrm.png";
import DemoComponent from "../../components/DemoComponent";
import minnoLogo from "../../images/minno.png";
import adminDemo from "../../images/admindemo.png";
import financeDemoImage from "../../images/financedemo.png"; // Import the analytics demo image
import mobileDemoImage from "../../images/mobiledemo.png"; // Import the support demo image
import erpDemoImage from "../../images/erpdemo.png"; // Import the support demo image
import nlogo from "../../images/nlogo.png";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import ChartComponent from "../../components/ChartComponent";
import TableComponent from "../../components/TableComponent";
import Nvidia from "../../images/nvidia.png";
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // Import Tippy's CSS
import demoGif from "../../images/finance.gif"; // Add this import for the GIF

function Home() {
  const [email, setEmail] = useState("");
  const [showConfetti, setShowConfetti] = useState(false);
  const [currentAction, setCurrentAction] = useState(null);
  const [demoToast, setDemoToast] = useState(null);
  const [isToastVisible, setIsToastVisible] = useState(false);
  const toastTimeoutRef = useRef(null);
  const [selectedFilter, setSelectedFilter] = useState("crm");
  const [isLoading, setIsLoading] = useState(false);
  const [showChart, setShowChart] = useState(false);
  const [demoQuery, setDemoQuery] = useState("");
  const [threadId, setThreadId] = useState(null);
  const [showTable, setShowTable] = useState(false);
  const [demoTitle, setDemoTitle] = useState("What do you want to do today?");
  const [showCustomerSupport, setShowCustomerSupport] = useState(false);
  const [customerSupportMessage, setCustomerSupportMessage] = useState("");

  const navigate = useNavigate();

  const openBlogPage = () => {
    navigate('/blog');
  };

  const handleFilterChange = (filter) => {
    setSelectedFilter(filter.toLowerCase());
  };

  // Modify the openCalendly function to handle cases where no event is passed
  const openCalendly = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    if (typeof window !== 'undefined' && window.Calendly) {
      window.Calendly.initPopupWidget({url: 'https://calendly.com/colin-angel/meet?hide_gdpr_banner=1'});
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Show confetti and notification immediately
    setShowConfetti(true);
    toast.success("Signup successful!", {
      position: "top-center",
      style: {
        backgroundColor: "#0C1B09",
        color: "#7FF257",
        border: "1px solid #3D493A",
      },
      iconTheme: {
        primary: "#7FF257",
        secondary: "#0C1B09",
      },
    });

    // Send the data to Google Sheets in the background
    try {
      const response = await fetch(
        "https://script.google.com/macros/s/AKfycbx4u2_GxoTyl7gZQj5eRLk1uwH6TaHh1MkqY5PmDdqHMejEiC4aADvkWTGnuEWtiUB3pA/exec",
        {
          method: "POST",
          body: new URLSearchParams({ email }),
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        },
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
      toast.error("There was an error submitting the form.");
    }

    // Reset the form
    setEmail("");

    // Stop confetti after a delay
    setTimeout(() => {
      setShowConfetti(false);
    }, 3000); // 3 seconds
  };

  const handleCopyLink = useCallback(() => {
    navigator.clipboard
      .writeText(window.location.href)
      .then(() => {
        toast.success("Link copied to clipboard!", {
          style: {
            backgroundColor: "#0C1B09",
            color: "#7FF257",
            border: "1px solid #3D493A",
          },
          iconTheme: {
            primary: "#7FF257",
            secondary: "#0C1B09",
          },
        });
      })
      .catch((err) => {
        console.error("Failed to copy text: ", err);
        toast.error("Failed to copy link", {
          style: {
            backgroundColor: "#0C1B09",
            color: "#FF6C3D",
            border: "1px solid #3D493A",
          },
          iconTheme: {
            primary: "#FF6C3D",
            secondary: "#0C1B09",
          },
        });
      });
  }, []);

  const actions = [
    {
      text: "Can you export my data?",
      toastMessage: "Data exported successfully!",
      action: () => {},
    },
    {
      text: "Can I share this somehow?",
      toastMessage: "Yes, we've copied the URL to your clipboard!",
      action: () => {
        navigator.clipboard
          .writeText(window.location.href)
          .then(() => {})
          .catch((err) => {
            console.error("Failed to copy text: ", err);
            toast.error("Failed to copy link");
          });
      },
    },
    {
      text: "Where's the brand kit?",
      toastMessage: "Brand kit downloaded successfully!",
      action: () => {},
    },
    {
      text: "Can you turn off auto-play?",
      toastMessage: "Auto-play has been disabled!",
      action: () => {},
    },
  ];

  const handleActionPerformed = useCallback(
    (index) => {
      const action = actions[index];
      setCurrentAction(action);
      action.action();
    },
    [actions],
  );

  const handleActionComplete = useCallback(() => {
    setCurrentAction(null);
  }, []);

  const handleDemoToast = (message) => {
    setDemoToast(message);

    // Clear any existing timeouts
    if (toastTimeoutRef.current) {
      clearTimeout(toastTimeoutRef.current);
    }

    // Set a small delay before showing the toast to ensure the DOM has updated
    setTimeout(() => {
      setIsToastVisible(true);
    }, 50);

    // Set the timeout for hiding the toast
    toastTimeoutRef.current = setTimeout(() => {
      setIsToastVisible(false);
      toastTimeoutRef.current = setTimeout(() => setDemoToast(null), 300);
    }, 5000);
  };

  useEffect(() => {
    return () => {
      if (toastTimeoutRef.current) {
        clearTimeout(toastTimeoutRef.current);
      }
    };
  }, []);

  const demoImages = {
    crm: packedCRM,
    erp: erpDemoImage,
    finance: financeDemoImage,
    mobile: mobileDemoImage,
  };

  const [isHighlighted, setIsHighlighted] = useState(false);
  const [inputText, setInputText] = useState("");
  const [conversation, setConversation] = useState([]);
  const conversationRef = useRef(null);

  const handleHighlight = () => {
    setIsHighlighted(true);
    setTimeout(() => setIsHighlighted(false), 3000); // Highlight for 3 seconds
  };

  const handleSubmitDemo = async (e) => {
    e.preventDefault();
    if (!inputText.trim()) return;

    const userMessage = { role: "user", content: inputText };
    setConversation((prev) => [...prev, userMessage]);
    setInputText("");
    setIsLoading(true);

    try {
      const response = await fetch("/api/gpt", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ prompt: inputText, threadId }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(
          `Failed to get AI response: ${errorData.error}. Details: ${errorData.details}`,
        );
      }

      const data = await response.json();
      if (data.error) {
        throw new Error(data.error);
      }
      const aiMessage = { role: "assistant", content: data.response };
      setConversation((prev) => [...prev, aiMessage]);
      setThreadId(data.threadId);
    } catch (error) {
      console.error("Error:", error);
      const errorMessage = {
        role: "assistant",
        content: `Error: ${error.message}`,
      };
      setConversation((prev) => [...prev, errorMessage]);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (conversationRef.current) {
      conversationRef.current.scrollTop = conversationRef.current.scrollHeight;
    }
  }, [conversation]);

  const tableHeaders = ["Name", "Age", "Country"];
  const tableRows = [
    ["John Doe", "30", "USA"],
    ["Jane Smith", "25", "Canada"],
    ["Sam Brown", "22", "UK"],
  ];

  // Update the handleRecentQuery function
  const handleRecentQuery = (query, componentType) => {
    setDemoQuery(query);
    setIsLoading(true);
    setShowChart(false);
    setShowTable(false);
    setShowCustomerSupport(false);
    setDemoTitle(query);

    // Simulate loading
    setTimeout(() => {
      setIsLoading(false);
      if (componentType === 'chart') {
        setShowChart(true);
      } else if (componentType === 'table') {
        setShowTable(true);
      } else if (componentType === 'customerSupport') {
        setShowCustomerSupport(true);
        setCustomerSupportMessage("Hello! How can I assist you today?");
      } else if (componentType === 'openCalendly') {
        openCalendly();
      }
    }, 1000);
  };

  const [showTooltip, setShowTooltip] = useState(false);

  const handleInputFocus = () => {
    setShowTooltip(true);
  };

  const handleInputBlur = () => {
    setShowTooltip(false);
  };

  return (
    <div className="home">
      <Toaster
        position="top-center"
        toastOptions={{
          success: {
            style: {
              backgroundColor: "#0C1B09",
              color: "#7FF257",
              border: "1px solid #3D493A",
            },
            iconTheme: {
              primary: "#7FF257",
              secondary: "#0C1B09",
            },
          },
          error: {
            style: {
              background: "#0C1B09",
              color: "#FF6C3D",
            },
            iconTheme: {
              primary: "#FF6C3D",
              secondary: "#0C1B09",
            },
          },
        }}
      />
      {showConfetti && <Confetti />}
      <header className="App-header">
        <Navbar onCopyLink={handleCopyLink} />
        <div className="hero-container">
          <div className="hero">
            <div className="text-content">
              <h1 id="header">
                Intelligent financial analysis{" "}
                <span className="highlight">assistant</span>
              </h1>
              <p id="sub-header">
                Instant data retrieval and analysis for your users whenever they need it
              </p>

              <button 
                className="input-button"
                data-tally-open="31vjVM"
                data-tally-layout="modal"
                data-tally-width="600"
                data-tally-emoji-text="🌿"
                data-tally-emoji-animation="heart-beat"
                data-tally-auto-close="2000"
              >
                Sign up
              </button>
              <button className="input-button-2" onClick={openCalendly}>Book a demo</button>
            </div>
          </div>

          {/* Replace the demo-menu-container with the GIF */}
          <div className="demo-gif-container">
            <img src={demoGif} alt="Product Demo" className="demo-gif" />
          </div>

        </div>
      </header>
      <main>

      <div className="quick-features">

      <div className="features-container">
              <div className="features">
                <div className="feature-item">
                  <img src={locationIcon} id="icon" alt="Natural AI control" />
                  <h2 id="feature-heading">Complete actions</h2>
                  <p id="feature-text">
                    Natural can assist users with any action in your finance tool, right from the chat. Fetch data, display reports, and more.
                  </p>
                </div>
                <div className="feature-item">
                  <img src={chartIcon} id="icon" alt="Natural UI chart" />
                  <h2 id="feature-heading">Custom charts</h2>
                  <p id="feature-text">
                    Natural can generate custom charts and reports directly from user queries.
                  </p>
                </div>
                <div className="feature-item">
                  <img
                    src={helpIcon}
                    id="icon"
                    alt="Replace help docs with Natural UI"
                  />
                  <h2 id="feature-heading">Help docs are dead</h2>
                  <p id="feature-text">
                    Natural uses your internal knowledge base to answer questions immediately, without interrupting the user's flow.
                  </p>
                </div>
              </div>
            </div>
      </div>

      <div className="founders">
            <p className="trusted-by-text">Trusted by</p>
              <div className="logos">
                <img src={leapyearLogo} id="leapyear" alt="Leapyear" />
                <img src={pioneerLogo} id="pioneer" alt="YC Pioneer Program" />
                <img src={Nvidia} id="nvidia" alt="Nvidia Inception Program" />
      
              </div>
            </div>

        <div className="main-features">


          
          <h2 id="features-text">
            Better UX, <span className="highlight">faster insights</span>
          </h2>

          <div className="features-section">
            <div className="features-2">
              <div className="feature-item-2">
                <img
                  src={bulbIcon}
                  id="icon"
                  alt="Natural UI product insights"
                />
                <h2 id="feature-heading">Product insights</h2>
                <p id="feature-text">
                  View & analyze user prompts. Understand your users' pain
                  points and desired features.
                </p>
              </div>
              <div className="feature-item-2">
                <img
                  src={chartIcon}
                  id="icon"
                  alt="We are your UI's best friend"
                />
                <h2 id="feature-heading">Higher customer engagement</h2>
                <p id="feature-text">
                  Your users will keep coming back because Natural is always
                  there to help them.
                </p>
              </div>
            </div>
          </div>

          <div className="demo-section-container-2">
            <div className="demo-section-2">
              <div className="demo-content-2">
                <img
                  src={adminDemo}
                  alt="Natural UI admin dashboard"
                  className="demo-image"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="dev-section-container">
          <h2 id="features-text">
            Easy for <span className="highlight">developers</span>
          </h2>
          <div className="dev-section">
            <div className="feature-item">
              <img src={devIcon} id="icon" alt="Simple AI integration" />
              <h2 id="feature-heading">Simple integration</h2>
              <p id="feature-text">
                Upload your API docs and add a code snippet to your website. It's that easy.
              </p>
            </div>
            <div className="feature-item">
              <img
                src={chartIcon}
                id="icon"
                alt="Natural UX starts with direct integration"
              />
              <h2 id="feature-heading">Optimized for React/Next.js</h2>
              <p id="feature-text">
                Natural calls functions and renders components directly from your codebase.
              </p>
            </div>
          </div>
        </div>

        <div className="story">
          <h1 id="story-heading">
            An intelligent <span className="highlight">financial assistant</span>
          </h1>
          <div className="story-content">
            <p id="story-text">
              When users are lost when performing financial analysis, the last thing they want
              to do is to waste time searching for answers. Natural
              meets users where they are and instantly knows how to complete any
              task in your finance tool.
            </p>
            <ul>
              <li>
                <strong>AI Control:</strong> Users want to get the most out of your finance tool, fast. Natural completes manual tasks for them in seconds.
              </li>
              <li>
                <strong>Insights:</strong> When users struggle to find the right data, that's important information for improving your product. Natural provides insights into user intent, so you know exactly what they want.
              </li>
              <li>
                <strong>Easy:</strong> Simply upload your API docs and add a code snippet to your product (like
                Posthog) and you're ready to go
              </li>
            </ul>
            <p id="story-text">
              Our goal is to improve user experience for financial analysis. Join us in our mission to make
              the world's financial information more accessible
            </p>
            <h3>Nico and Charlie</h3>
            <p>Founders of Natural</p>
          </div>
        </div>
      </main>
      <footer>
        <div className="inner-footer">
          <div className="footer-logo">
            <h3 id="natural-logo">🌿 Natural</h3>
            <div className="contact-link">
              <a href="mailto:founders@naturalui.com">Contact us</a>
            </div>


            <div className="contact-link">
              <a href="#" onClick={openBlogPage}>Blog</a>
            </div>
          </div>
          <div className="main-buttons">
          <button
                    type="submit"
                    className="input-button"
                
                  >
                    Sign up
                  </button>
                  <button
                    type="submit"
                    className="input-button-2"
                
                  >
                    Book a demo
                  </button>
                  </div>
        </div>
      </footer>
      <a href="#" className="natural-action" onClick={handleHighlight}>
        <img src={nlogo} alt="Natural Logo" width={24} height={24} />
      </a>
    </div>
  );
}

export default Home;